import { PopUpMain } from "../../component/popUp";
import {
  Box,
  Button,
  MenuItem,
  Skeleton,
  Stack,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from "formik";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AppDispatch, RootState } from "../../store/store";
import { initialSteelOnOrderData } from "./data";
import {
  addSteelOnOrder,
  editSteelOnOrder,
} from "../../api/steelonorder/Api.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { deleteAndShift } from "../../utils/deleteAndShift";

const errorStyles = {
  marginLeft: "0px",
  marginRight: "0px",
};

export const AddSteelOnOrder = (props: any): JSX.Element => {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={false}>
        <Box>
          <AddSteelOnOrderScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
};

export const AddSteelOnOrderScreen = (props: any): JSX.Element => {
  const emptySteelObject = {
    steelId: "",
    code: "",
    brand: "",
    quantity: "",
    status: "",
    purchaseDate: "",
    deliveryDate: "",
    outstandingQty: null,
    forecastedDate: null,
  };

  const { handleClose, editData, refreshSteelOnOrder, steelListData } = props;

  const dispatch: AppDispatch = useDispatch();
  const { masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const [code, setCode] = React.useState({});

  const handleOnDateChange = (
    event: DateTime | null,
    fieldName: any,
    Name: any,
    index: number
  ) => {
    if (
      Name.includes("purchaseDate") ||
      Name.includes("forecastedDate") ||
      Name.includes("deliveryDate")
    ) {
      if (event) {
        const tempDate = event.toISODate();
        fieldName(Name, tempDate);
      } else {
        fieldName(Name, "");
      }
    }
  };

  const handleChange = (event: any, fieldName: any, Name: any, index: any) => {
    let {
      target: { value },
    } = event;
    if (Name.includes("status")) {
      if (value !== "BACK_ORDER") {
        fieldName(`steelUsed[${index}].forecastedDate`, null);
        fieldName(`steelUsed[${index}].outstandingQty`, null);
      }
    }
    if (Name.includes("steelId")) {
      // empty steel and brand on code change
      fieldName(`steelUsed[${index}].code`, "");
      fieldName(`steelUsed[${index}].brand`, "");
      fieldName(`steelUsed[${index}].quantity`, "");
      fetchNewSteels(value, index);
    }

    if (Name) fieldName(Name, value);
  };

  const fetchNewSteels = (value: any, index: number) => {
    const resp = steelListData.filter((steel: any) => steel.id === value);
    const tempKey = `index-${index}`;
    setCode({
      ...code,
      [tempKey]: resp,
    });
  };

  const handleCallBack = () => {
    refreshSteelOnOrder();
    handleClose();
  };

  React.useEffect(() => {
    if (editData?._id) {
      const editSteel = editData?.steelUsed?.reduce(
        (acc: any, steel: any, index: number) => {
          const response = steelListData.filter(
            (steelData: any) => steelData._id === steel.steelId
          );
          const tempKey = `index-${index}`;
          acc[tempKey] = response;
          return acc;
        },
        {}
      );
      setCode(editSteel);
    }
  }, [editData, steelListData]);

  const validationSchema = yup.object({
    purchaseOrderNo: yup
      .string()
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    supplier: yup
      .string()
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    steelUsed: yup.array().of(
      yup.object().shape({
        quantity: yup
          .number()
          .min(1, "Qty must be greater than 1")
          .max(999.99, "Qty is exceeding the limit"),
        outstandingQty: yup
          .number()
          .nullable()
          .min(1, "Qty must be greater than 1")
          .max(999.99, "Qty is exceeding the limit"),
      })
    ),
  });

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" align="left">
                    Asset type
                  </Typography>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "5vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle1" align="left">
                    Add steel and quantity for each asset:
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Formik
              initialValues={
                editData?._id
                  ? editData
                    ? editData
                    : initialSteelOnOrderData
                  : initialSteelOnOrderData
              }
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const valuesNew = {
                  ...values,
                  locationId: masterLocation.locationId,
                };

                const tempSteelUsed = valuesNew.steelUsed.map((item: any) => {
                  const temp = {
                    ...item,
                  };
                  if (temp.status !== "BACK_ORDER") {
                    delete temp.outstandingQty;
                    delete temp.forecastedDate;
                  }
                  return temp;
                });
                delete valuesNew.brand;
                delete valuesNew.code;
                valuesNew.steelUsed = tempSteelUsed;
                const data = {
                  data: valuesNew,
                  callback: handleCallBack,
                };
                if (editData?._id) {
                  const updateData = {
                    data: { ...valuesNew, _id: editData?._id },
                    callback: handleCallBack,
                  };
                  // delete updateData.data.locationId;
                  // delete updateData.data.locationName;
                  delete updateData.data.totalQty;
                  dispatch(editSteelOnOrder(updateData));
                } else {
                  dispatch(addSteelOnOrder(data));
                }
              }}
            >
              {({ values, resetForm, isSubmitting, errors, setFieldValue }) => (
                <>
                  <Box
                    ml={0}
                    sx={{
                      p: 3,
                      backgroundColor: "#fff ",
                    }}
                  >
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Purchase Order No.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`purchaseOrderNo`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <TextField
                                    label="Purchase Order No."
                                    name={`purchaseOrderNo`}
                                    id={`purchaseOrderNo`}
                                    type="text"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    // value={values?.purchaseOrderNo}
                                    value={field.value ?? ""}
                                    onChange={(event: any) => {
                                      handleChange(
                                        event,
                                        setFieldValue,
                                        `purchaseOrderNo`,
                                        undefined
                                      );
                                    }}
                                  />
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="right">
                            Supplier
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          pb={2}
                        >
                          <Field name={`supplier`}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <FormControl>
                                  <TextField
                                    label="Supplier"
                                    name={`supplier`}
                                    id={`supplier`}
                                    type="text"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    // value={values?.supplier}
                                    value={field.value ?? ""}
                                    onChange={(event: any) => {
                                      handleChange(
                                        event,
                                        setFieldValue,
                                        `supplier`,
                                        undefined
                                      );
                                    }}
                                  />
                                  <FormHelperText
                                    error={!!meta.error}
                                    style={errorStyles}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid>
                        <FieldArray name="steelUsed">
                          {({ push, remove }) => (
                            <React.Fragment>
                              {values?.steelUsed?.map((_: any, index: any) => (
                                <div className="add-more-container-a">
                                  <Grid container item key={index} spacing={2}>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={
                                          values?.steelUsed[index]?.steelId
                                        }
                                        label="Product Name"
                                        required
                                        placeholder="Product Name"
                                        className="custom-select"
                                        disabled={
                                          editData?.steelUsed?.length > index
                                        }
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].steelId`,
                                            index
                                          )
                                        }
                                      >
                                        {steelListData?.map((option: any) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option?.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.steelUsed[index]?.code}
                                        placeholder="Code"
                                        label="Code"
                                        disabled={
                                          editData?.steelUsed?.length > index
                                        }
                                        className="custom-select"
                                        isMulti={true}
                                        required
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].code`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(code).length ? (
                                          // @ts-ignore
                                          code[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.code}
                                                >
                                                  {option?.code}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        // name={`steelUsed[${index}].brand`}
                                        // id={`steelUsed[${index}].brand`}
                                        value={values?.steelUsed[index]?.brand}
                                        placeholder="Brand"
                                        disabled={
                                          editData?.steelUsed?.length > index
                                        }
                                        required
                                        label="Brand"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].brand`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(code).length ? (
                                          // @ts-ignore
                                          code[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.brand}
                                                >
                                                  {option?.brand}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`steelUsed[${index}].brand`}
                                      />
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`steelUsed[${index}].quantity`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Quantity"
                                              name={`steelUsed[${index}].quantity`}
                                              id={`steelUsed[${index}].quantity`}
                                              type="number"
                                              required
                                              size="small"
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `steelUsed[${index}].quantity`,
                                                  index
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                      {/* <Field
                                        fullWidth
                                        size="small"
                                        id={`steelUsed[${index}].quantity`}
                                        name={`steelUsed[${index}].quantity`}
                                        value={values.steelUsed[index].quantity}
                                        component={TextField}
                                        type="text"
                                        required
                                        label="Quantity"
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].quantity`,
                                            index
                                          )
                                        }
                                      /> */}
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        // name={`steelUsed[${index}].brand`}
                                        // id={`steelUsed[${index}].brand`}
                                        value={values?.steelUsed[index]?.status}
                                        placeholder="Status"
                                        required
                                        label="Status"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].status`,
                                            index
                                          )
                                        }
                                      >
                                        {/* <MenuItem
                                          key={"random"}
                                          value={"INCOMING_SHIPMENT"}
                                        >
                                          {"Incoming Shipment"}
                                        </MenuItem> */}
                                        <MenuItem
                                          key={"random"}
                                          value={"ON_ORDER"}
                                        >
                                          {"On Order"}
                                        </MenuItem>
                                        <MenuItem
                                          key={"random"}
                                          value={"BACK_ORDER"}
                                        >
                                          {"Back Order"}
                                        </MenuItem>
                                        <MenuItem
                                          key={"random"}
                                          value={"FULFILLED"}
                                        >
                                          {"Fulfilled"}
                                        </MenuItem>
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`steelUsed[${index}].status`}
                                      />
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Purchase Date"
                                          format="dd/MM/yyyy"
                                          onChange={(event) =>
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `steelUsed[${index}].purchaseDate`,
                                              index
                                            )
                                          }
                                          value={
                                            values?.steelUsed[index]
                                              ?.purchaseDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.steelUsed[
                                                      index
                                                    ]?.purchaseDate
                                                  )
                                                )
                                              : null
                                          }
                                          maxDate={DateTime.fromJSDate(
                                            new Date(
                                              values?.steelUsed[
                                                index
                                              ]?.deliveryDate
                                            )
                                          )}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `steelUsed[${index}].purchaseDate`,
                                              name: `steelUsed[${index}].purchaseDate`,
                                              required: true,
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Expected Delivery Date"
                                          format="dd/MM/yyyy"
                                          onChange={(event) =>
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `steelUsed[${index}].deliveryDate`,
                                              index
                                            )
                                          }
                                          value={
                                            values?.steelUsed[index]
                                              ?.deliveryDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.steelUsed[
                                                      index
                                                    ]?.deliveryDate
                                                  )
                                                )
                                              : null
                                          }
                                          minDate={DateTime.fromJSDate(
                                            new Date(
                                              values?.steelUsed[
                                                index
                                              ]?.purchaseDate
                                            )
                                          )}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `steelUsed[${index}].deliveryDate`,
                                              name: `steelUsed[${index}].deliveryDate`,
                                              required:
                                                values.steelUsed[index]
                                                  .status !== "ON_ORDER",
                                              // required: true,
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        name={`steelUsed[${index}].outstandingQty`}
                                      >
                                        {({ field, meta }: FieldProps) => (
                                          <FormControl>
                                            <TextField
                                              label="Outstanding Quantity"
                                              name={`steelUsed[${index}].outstandingQty`}
                                              id={`steelUsed[${index}].outstandingQty`}
                                              type="number"
                                              size="small"
                                              disabled={
                                                values.steelUsed[index]
                                                  .status !== "BACK_ORDER"
                                              }
                                              required={
                                                values.steelUsed[index]
                                                  .status === "BACK_ORDER"
                                              }
                                              value={field.value ?? ""}
                                              onChange={(event: any) => {
                                                handleChange(
                                                  event,
                                                  setFieldValue,
                                                  `steelUsed[${index}].outstandingQty`,
                                                  index
                                                );
                                              }}
                                            />
                                            <FormHelperText
                                              error={!!meta.error}
                                              style={errorStyles}
                                            >
                                              {meta.error}
                                            </FormHelperText>
                                          </FormControl>
                                        )}
                                      </Field>
                                      {/* <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <Field
                                        fullWidth
                                        size="small"
                                        id={`steelUsed[${index}].outstandingQty`}
                                        name={`steelUsed[${index}].outstandingQty`}
                                        value={
                                          values.steelUsed[index].outstandingQty
                                        }
                                        component={TextField}
                                        type="text"
                                        disabled={
                                          values.steelUsed[index].status !==
                                          "BACK_ORDER"
                                        }
                                        required={
                                          values.steelUsed[index].status ===
                                          "BACK_ORDER"
                                        }
                                        label="Outstanding Quantity"
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].outstandingQty`,
                                            index
                                          )
                                        }
                                      />
                                    </Grid> */}
                                    </Grid>
                                    <Grid item xs={1.25} sm={1.25} md={1.25}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterLuxon}
                                      >
                                        <DatePicker
                                          label="Forecasted Date"
                                          format="dd/MM/yyyy"
                                          onChange={(event) =>
                                            handleOnDateChange(
                                              event,
                                              setFieldValue,
                                              `steelUsed[${index}].forecastedDate`,
                                              index
                                            )
                                          }
                                          value={
                                            values?.steelUsed[index]
                                              ?.forecastedDate
                                              ? DateTime.fromJSDate(
                                                  new Date(
                                                    values?.steelUsed[
                                                      index
                                                    ]?.forecastedDate
                                                  )
                                                )
                                              : null
                                          }
                                          disabled={
                                            values.steelUsed[index].status !==
                                            "BACK_ORDER"
                                          }
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              fullWidth: true,
                                              id: `steelUsed[${index}].forecastedDate`,
                                              name: `steelUsed[${index}].forecastedDate`,
                                              disabled: Boolean(
                                                values.steelUsed[index]
                                                  .status !== "BACK_ORDER"
                                              ),
                                              required: Boolean(
                                                values.steelUsed[index]
                                                  .status === "BACK_ORDER"
                                              ),
                                            },
                                            actionBar: {
                                              actions: ["clear"],
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={0.75} sm={0.75} md={0.75}>
                                      <div className="remove-add-more-item">
                                        {values?.steelUsed.length > 1 ? (
                                          <IconButton
                                            onClick={() => {
                                              remove(index);
                                              deleteAndShift(code, index);
                                            }}
                                          >
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}

                              <Grid item>
                                {typeof errors.steelUsed === "string" ? (
                                  <Typography color="error">
                                    {errors.steelUsed}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <IconButton
                                  disabled={isSubmitting}
                                  onClick={() => push(emptySteelObject)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </div>
                            </React.Fragment>
                          )}
                        </FieldArray>
                      </Grid>
                      <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {"Save"}
                        </Button>
                        <Button variant="outlined" onClick={handleClose}>
                          Cancel
                        </Button>
                        {editData?._id ? (
                          <></>
                        ) : (
                          <Button
                            variant="outlined"
                            type="reset"
                            onClick={(e) => resetForm()}
                            color="error"
                          >
                            Reset
                          </Button>
                        )}
                      </Box>
                    </Form>
                  </Box>
                </>
              )}
            </Formik>
          </>
        )}
      </>
    </React.Fragment>
  );
};
